export default [
  {
    icon: 'SettingsIcon',
    title: 'Settings',
    children: [
      {
        icon: 'UsersIcon',
        title: 'Clients',
        route: 'settings-clients-list',
        resource: 'Clients',
        action: 'Access',
      },
      {
        icon: 'UsersIcon',
        title: 'Permissions',
        route: 'settings-permissions-list',
        resource: 'Permissions',
        action: 'Access',
      },
      {
        icon: 'UsersIcon',
        title: 'Roles',
        route: 'settings-roles-list',
        resource: 'Roles',
        action: 'Access',
      },

      {
        icon: 'UsersIcon',
        title: 'Users',
        route: 'settings-users-list',
        resource: 'Users',
        action: 'Access',
      },
    ],
  },
]
