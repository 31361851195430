export default [
  {
    icon: 'PieChartIcon',
    title: 'Reports',
    children: [

      // เมนูหมวด PowerBI
      {
        icon: 'PieChartIcon',
        title: 'Sales Performance (TH)',
        route: 'powerbi-sales-performance-th',
        resource: 'Sales Performance (TH)',
        action: 'Access',
      },
      // {
      //   icon: 'PieChartIcon',
      //   title: 'Payment Calendar',
      //   route: 'powerbi-payment-calendar',
      //   resource: 'Payment Calendar',
      //   action: 'Access',
      // },
      {
        icon: 'PieChartIcon',
        title: 'Benchmark By Industry',
        route: 'powerbi-benchmark-by-industry',
        resource: 'Benchmark By Industry',
        action: 'Access',
      },

      // เมนูหมวด Google Sheet

      {
        icon: 'FileTextIcon',
        title: 'PMKT Bi-Weekly MOM',
        route: 'google-sheet-pmkt-bi-weekly-mom',
        resource: 'PMKT Bi-Weekly MOM',
        action: 'Access',
      },

      {
        icon: 'FileTextIcon',
        title: 'Missing ACI',
        route: 'google-sheet-missing-aci',
        resource: 'Missing ACI',
        action: 'Access',
      },
    ],
  },
]
