export default [
  {
    icon: 'CloudIcon',
    title: 'Salesforce Information',
    children: [

      {
        icon: 'UsersIcon',
        title: 'Accounts',
        route: 'accounts-list',
        resource: 'Accounts',
        action: 'Access',
      },
      {
        icon: 'FlagIcon',
        title: 'Opportunities',
        route: 'opportunities-list',
        resource: 'Opportunities',
        action: 'Access',
      },
    ],
  },
]
