export default [
  {
    icon: 'MenuIcon',
    title: 'Masters',
    children: [
      {
        icon: 'MenuIcon',
        title: 'Countries',
        route: 'masters-countries-list',
        resource: 'Countries',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'KPI Units',
        route: 'masters-kpi-units-list',
        resource: 'KPI Units',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'Languages',
        route: 'masters-languages-list',
        resource: 'Languages',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'Lead Sources',
        route: 'masters-lead-sources-list',
        resource: 'Lead Sources',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'Metric Units',
        route: 'masters-metric-units-list',
        resource: 'Metric Units',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'Campus Point Limits',
        route: 'masters-campas-point-limit-list',
        resource: 'Campus Point Limits',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'User Positions',
        route: 'masters-user-positions-list',
        resource: 'User Positions',
        action: 'Access',
      },
      {
        icon: 'MenuIcon',
        title: 'Exchange Rates',
        route: 'masters-exchange-rates-list',
        resource: 'Exchange Rates',
        action: 'Access',
      },
    ],
  },
]
