export default [
  {
    icon: 'ShoppingBagIcon',
    title: 'Product Offerings',
    children: [
      {
        icon: 'ShoppingBagIcon',
        title: 'Products',
        route: 'products-list',
        resource: 'Products',
        action: 'Access',
      },
      {
        icon: 'ShoppingBagIcon',
        title: 'Product Families',
        route: 'product-families-list',
        resource: 'Products Families',
        action: 'Access',
      },
    ],
  },
]
