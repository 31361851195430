export default [
  {
    icon: 'ActivityIcon',
    title: 'Performance & Insight Suite',
    children: [
      {
        icon: 'SlidersIcon',
        title: 'Channel Performance',
        route: 'channel-performance',
        resource: 'Channel Performance',
        action: 'Access',
      },
      {
        icon: 'PieChartIcon',
        title: 'ICONIC Campaign Monitor',
        route: 'powerbi-iconic-campaign-monitor',
        resource: 'ICONIC Campaign Monitor',
        action: 'Access',
      },
      {
        icon: 'TrendingUpIcon',
        title: 'HeroVisionX',
        route: 'herovision-index',
        resource: 'HeroVisionX',
        action: 'Access',
      },
    ],
  },
]
